import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { EmptyState, PrimaryButton } from '@agentero/components';
import { IconFindInPage } from '@agentero/icons';
import { Role } from '@agentero/models/agent';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { dashboardRoute } from 'routes';

export const NotFoundError = () => {
	const { t } = useTranslation('error');
	const {
		data: { role }
	} = useAgentResource();

	return (
		<EmptyState>
			<EmptyState.Icon>
				<IconFindInPage />
			</EmptyState.Icon>
			<EmptyState.Title>{t('notFound.title')}</EmptyState.Title>
			<EmptyState.Body>{t('notFound.body')}</EmptyState.Body>
			{role !== Role.SuperAdmin && (
				<EmptyState.Actions>
					<Link href={dashboardRoute} passHref legacyBehavior>
						<PrimaryButton as="a">{t('notFound.actions.primary')}</PrimaryButton>
					</Link>
				</EmptyState.Actions>
			)}
		</EmptyState>
	);
};
