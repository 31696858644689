import { ComponentType, PropsWithChildren, SVGProps } from 'react';

import Link from 'next/link';

import { NavButton, NavButtonIcon } from './NavButton';
import { useIsActiveLink } from './useIsActiveLink';

type NavLinkProps = {
	href: string;
	as?: string;
	target?: string;
	rel?: string;
	Icon: ComponentType<SVGProps<SVGSVGElement>>;
};

export const NavLink = ({
	href,
	children,
	Icon,
	as,
	...props
}: PropsWithChildren<NavLinkProps>) => {
	const isActive = useIsActiveLink(href);

	return (
		<Link href={href} as={as} passHref legacyBehavior>
			<NavButton className={isActive ? 'is-active' : ''} {...props}>
				<NavButtonIcon>
					<Icon />
				</NavButtonIcon>
				{children}
			</NavButton>
		</Link>
	);
};
