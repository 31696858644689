import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { IButton, PrimaryButton, PrimaryButtonDark } from '@agentero/components';

import { raterRoute } from 'routes';

type GetQuotesButtonProps = IButton & {
	isDark?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const GetQuotesButton = ({ isDark = false, ...props }: GetQuotesButtonProps) => {
	const { t } = useTranslation(['marketAccess', 'rater', 'shared']);
	const Button = isDark ? PrimaryButtonDark : PrimaryButton;

	return (
		<Link href={raterRoute} passHref legacyBehavior>
			<Button data-cy="getquotes-button" {...props} as="a">
				{t('rater:getQuotes')}
			</Button>
		</Link>
	);
};
