import { FC, PropsWithChildren } from 'react';

import { IconClose, LogoAgentero } from '@agentero/icons';
import { styled } from '@agentero/styles/jsx';
import { Button } from '@agentero/ui';

type FullPageProps = {
	/**
	 * @deprecated It'll be removed when the new layout is fully implemented
	 */
	legacyBehavior?: boolean;
	'data-cy'?: string;
};

type FullPageHeaderProps = {
	onClose?: () => void;
};

type FullPageComponents = {
	Header: FC<FullPageHeaderProps>;
	Body: FC<PropsWithChildren>;
};

const FullPageRoot = styled('div', {
	base: {},
	variants: {
		legacyBehavior: {
			true: { flex: 1, minHeight: '0' }
		}
	}
});

const FullPageHeaderRoot = styled('header', {
	base: {
		position: 'sticky',
		zIndex: 2,
		top: 0,
		display: 'flex',
		gap: '24',
		padding: '24',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: 'white'
	}
});

const FullPageHeader = ({ onClose }: FullPageHeaderProps) => (
	<FullPageHeaderRoot>
		<LogoAgentero />

		{onClose && (
			<Button size="lg" variant="tertiary" onClick={onClose}>
				<IconClose />
			</Button>
		)}
	</FullPageHeaderRoot>
);

const FullPageBody = styled('div', {
	base: {
		position: 'relative',
		zIndex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '24'
	}
});

export const FullPage: FC<PropsWithChildren<FullPageProps>> & FullPageComponents = ({
	legacyBehavior = false,
	children,
	...props
}) => (
	<FullPageRoot legacyBehavior={legacyBehavior} data-cy={props['data-cy']}>
		{children}
	</FullPageRoot>
);

FullPage.Header = FullPageHeader;
FullPage.Body = FullPageBody;
