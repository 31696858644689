import React from 'react';

import { useTranslation } from 'react-i18next';

import { IconAccountCircle, IconDomain } from '@agentero/icons';
import { isSuperAdmin } from '@agentero/models/agent';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { agenciesRoute, agentsRoute } from 'routes';

import { NavItem } from './shared/NavItem';
import { NavLink } from './shared/NavLink';
import { NavList } from './shared/NavList';

export const SuperAdminNavList = () => {
	const { t } = useTranslation('nav');

	const {
		data: { role }
	} = useAgentResource();

	return isSuperAdmin(role) ? (
		<NavList data-cy="nav-list-super-admin">
			<NavItem>
				<NavLink href={agentsRoute} Icon={IconAccountCircle}>
					{t('agents')}
				</NavLink>
			</NavItem>

			<NavItem>
				<NavLink href={agenciesRoute} Icon={IconDomain}>
					{t('agencies')}
				</NavLink>
			</NavItem>
		</NavList>
	) : null;
};
