import React from 'react';

import styled from '@emotion/styled';

import { TerciaryButton } from '@agentero/components';
import { IconMenu } from '@agentero/icons';

import { useNavStore } from 'packages/contexts/useNavStore';

const NavOpenButtonContainer = styled(TerciaryButton)`
	position: absolute;
	z-index: 1;
	top: 14px;
	left: 14px;

	${({ theme }) => theme.mediaqueries.L} {
		display: none;
	}

	svg {
		width: 24px;
		height: 24px;
	}
`;

export const NavOpenButton = () => {
	const [, setIsOpen] = useNavStore();

	return (
		<NavOpenButtonContainer onClick={() => setIsOpen(true)} data-cy="nav-toggle">
			<IconMenu />
		</NavOpenButtonContainer>
	);
};
