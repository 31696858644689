import { useTranslation } from 'react-i18next';

import { EmptyState, PrimaryButton, TextLink } from '@agentero/components';
import { IconWarning } from '@agentero/icons';

type InternalServerErrorProps = {
	onReload: () => void;
};

export const InternalServerError = ({ onReload }: InternalServerErrorProps) => {
	const { t } = useTranslation('error');

	return (
		<EmptyState>
			<EmptyState.Icon>
				<IconWarning />
			</EmptyState.Icon>
			<EmptyState.Title>{t('internalServer.title')}</EmptyState.Title>
			<EmptyState.Body>
				{t('internalServer.body.start')}{' '}
				<TextLink href={`mailto:${t('internalServer.body.email')}`}>
					<b>{t('internalServer.body.email')}</b>
				</TextLink>{' '}
				{t('internalServer.body.end')}
			</EmptyState.Body>
			<EmptyState.Actions>
				<PrimaryButton onClick={onReload}>{t('internalServer.actions.primary')}</PrimaryButton>
			</EmptyState.Actions>
		</EmptyState>
	);
};
