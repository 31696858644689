import { useTranslation } from 'react-i18next';

import { IconArrowBack, IconHelp, IconPowerOff, IconSettings } from '@agentero/icons';
import { Role } from '@agentero/models/agent';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getSettingsAdminDetailsRoutePath, getSettingsAgentDetailsRoutePath } from 'routes';

import { NavBackAgLink } from './shared/NavBackAgLink';
import { NavItem } from './shared/NavItem';
import { NavLink } from './shared/NavLink';
import { NavList } from './shared/NavList';

export const NavSettings = () => {
	const { t } = useTranslation('nav');
	const {
		data: { id, role, isImpersonation }
	} = useAgentResource();

	return (
		<NavList stickyBottom>
			<NavItem>
				<NavLink
					href={
						role !== Role.SuperAdmin
							? getSettingsAgentDetailsRoutePath(id)
							: getSettingsAdminDetailsRoutePath(id)
					}
					Icon={IconSettings}>
					{t('settings')}
				</NavLink>
			</NavItem>
			<NavItem>
				<NavLink
					href="https://support.agentero.com/knowledge"
					Icon={IconHelp}
					target="_blank"
					rel="noopener noreferrer">
					{t('helpCenter')}
				</NavLink>
			</NavItem>
			<NavItem isAuthorized={isImpersonation}>
				<NavBackAgLink href="/impersonations" Icon={IconArrowBack}>
					{t('backToAdmin')}
				</NavBackAgLink>
			</NavItem>
			<NavItem>
				<NavBackAgLink href="/sign_out" Icon={IconPowerOff}>
					{t('logout')}
				</NavBackAgLink>
			</NavItem>
		</NavList>
	);
};
