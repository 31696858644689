import styled from '@emotion/styled';

import { Role } from '@agentero/models/agent';
import { isAnyBindWithAgenteroCarrier, isAnyCarrierAppointed } from '@agentero/models/quote';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useQuotableInsuranceTypesNoSuspenseQuery } from 'packages/services/fetch/carrier-fe/quotable-insurance-types/useQuotableInsuranceTypes';

import { GetQuotesButton } from '../GetQuotesButton';

const NavGetQuotesContainer = styled.div`
	padding: 24px 0 0;
`;

const NavWrapper = styled.div`
	width: 100%;
	max-width: 160px;
	margin: 0 auto;
`;

export const NavGetQuotes = () => {
	const { data: agent } = useAgentResource();
	const { data: raterLOBs = [], error } = useQuotableInsuranceTypesNoSuspenseQuery();
	if (error) return null;

	const hasQuotableCarrier = raterLOBs.some(
		lob =>
			isAnyCarrierAppointed(lob.quotableCarriers) ||
			isAnyBindWithAgenteroCarrier(lob.quotableCarriers)
	);

	const hasQuotesButton = hasQuotableCarrier && agent.role !== Role.SuperAdmin;

	return hasQuotesButton ? (
		<NavWrapper>
			<NavGetQuotesContainer>
				<GetQuotesButton isBig isBlock isDark />
			</NavGetQuotesContainer>
		</NavWrapper>
	) : null;
};
