import { useTranslation } from 'react-i18next';

import { StatusColor, StatusColorLabel } from '@agentero/components';
import { Tier } from '@agentero/models/tier';
import { Text, TextProps } from '@agentero/ui';

const statusColorMapping: { [key in Exclude<Tier, Tier.Unspecified>]: StatusColor } = {
	[Tier.Bronze]: StatusColor.Info,
	[Tier.Silver]: StatusColor.Success,
	[Tier.Gold]: StatusColor.Violet
};

type TierStatusLabelProps = {
	tier: Tier;
	size?: TextProps['size'];
	isPaymentFailed?: boolean;
	onClick?: () => void;
};

export const TierStatusLabel = ({
	tier,
	size = 'tiny',
	isPaymentFailed,
	onClick
}: TierStatusLabelProps) => {
	const { t } = useTranslation();

	const label = isPaymentFailed ? t(`tier.paymentFailed`) : t(`tier.${tier}`);

	return tier !== Tier.Unspecified ? (
		<StatusColorLabel
			status={isPaymentFailed ? StatusColor.Error : statusColorMapping[tier]}
			aria-label={label}
			onClick={onClick}>
			<Text size={size}>{label}</Text>
		</StatusColorLabel>
	) : null;
};
